@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  display: flex;
  width: 100%;
  height: 100%;
  font-family: "Poppins", sans-serif;
  flex: 1;
}

ul {
  outline: none;
}

body {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  background: #f8f9fa;
}

main {
  width: 100vw;
  height: auto;
}

.section__content {
  padding: 48px 16px;
}

body::-webkit-scrollbar,
.custom-scrollbar::-webkit-scrollbar {
  width: 9px !important;
}

body::-webkit-scrollbar-thumb,
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #b9bfc6;
  border-radius: 7px;
}

body::-webkit-scrollbar-track,
.custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 7px;
  background: #ffffff;
}
